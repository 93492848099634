<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <section class="search__container">
      <common-search :fields="fields" @search="onSearch"></common-search>
    </section>
    <section class="table__container">
      <a-table
        :columns="tblColumns"
        :data-source="tblData"
        :loading="loading"
        :pagination="pagination"
        rowKey="orderSn"
        @change="onPage"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="onDetail(record)">查看</a>
        </template>
      </a-table>
    </section>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { loadArea } from "@/services/DealerService";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import {
  fetchCorporationInfoByKeyword,
  fetchReturnOrderList,
  fetchSellerCorporationByKeyword
} from "@/services/OrderManageService";
import { tsFormat } from "@/components/DateUtils";

const tblColumns = [
  {
    title: "订单编号",
    dataIndex: "orderSn",
    width: "15%"
  },
  {
    title: "原订单号",
    dataIndex: "orderPurchaseSn",
    width: "15%"
  },
  {
    title: "客户",
    dataIndex: "purchaserCorporationName",
    width: "10%"
  },
  {
    title: "经营地址",
    dataIndex: "registeredAreaName",
    width: "15%"
  },
  {
    title: "商家",
    dataIndex: "producerCorporationName",
    width: "10%"
  },
  {
    title: "退款金额（元）",
    dataIndex: "totalPrice",
    width: "5%"
  },
  {
    title: "订单状态",
    dataIndex: "orderStatusValue",
    width: "5%"
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "15%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "10%"
  }
];

export default {
  name: "OperationReturnedOrderList",
  components: {
    Breadcrumb
  },
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "订单编号",
          key: "orderSn",
          component: "j-input"
        },
        {
          label: "原订单号",
          key: "purchaseOrderSn",
          component: "j-input"
        },
        {
          label: "客户",
          key: "purchaserDistributorId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchCorporation(value);
          }
        },
        {
          label: "商家",
          key: "factoryId",
          component: "j-select-get",
          options: [],
          getOptions: value => {
            return this.onSearchSellerCorporation(value);
          }
        },
        {
          label: "订单状态",
          key: "orderStatus",
          component: "j-select",
          defaultValue: "all",
          options: [
            {
              label: "全部",
              value: "all"
            },
            {
              label: "商家审核中",
              value: "to_audit"
            },
            {
              label: "商家退款中",
              value: "to_payment"
            },
            {
              label: "签约中",
              value: "signing"
            },
            {
              label: "待审核",
              value: "to_hq_approval"
            },
            {
              label: "待退款审核",
              value: "to_hq_payment_approval"
            },
            {
              label: "客户发货中",
              value: "to_deliver"
            },
            {
              label: "商家收货中",
              value: "to_receive"
            },
            {
              label: "已完成",
              value: "complete"
            },
            {
              label: "已取消",
              value: "canceled"
            },
            {
              label: "已退回",
              value: "returned"
            }
          ]
        },
        {
          label: "经营地址",
          key: "registeredProvinceCode,registeredCityCode,registeredCountyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        },
        {
          label: "申请时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {
        orderStatus: "all"
      },
      areaData: [],
      tblColumns,
      tblData: [],
      pagination: {
        showQuickJumper: true
      },
      loading: false,
      tsFormat
    };
  },
  mounted() {
    this.loadAreaData();
    this.loadList();
  },
  activated() {
    this.loadList();
  },
  methods: {
    // 加载行政区数据
    async loadAreaData() {
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        this.areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return this.areaData;
    },
    // 搜索下单机构
    async onSearchCorporation(value) {
      let options = [];
      const resp = await fetchCorporationInfoByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 搜索卖方机构
    async onSearchSellerCorporation(value) {
      let options = [];
      const resp = await fetchSellerCorporationByKeyword(value);
      if (resp.data.code === "SUCCESS") {
        resp.data.data.forEach(item => {
          options.push({
            value: item.distributorId,
            label: `${item.corporationName}(${item.distributorTypeValue})`
          });
        });
      }
      return options;
    },
    // 加载列表
    loadList() {
      this.loading = true;
      const params = {
        factoryId: this.form.factoryId || "",
        orderCategory: "RETURNED",
        orderSn: this.form.orderSn || "",
        purchaseOrderSn: this.form.purchaseOrderSn || "",
        purchaserDistributorId: this.form.purchaserDistributorId || "",
        orderStatus: this.form.orderStatus || "",
        startTime: this.form.startTime ? this.form.startTime : "",
        endTime: this.form.endTime ? this.form.endTime : "",
        registeredProvinceCode: this.form.registeredProvinceCode
          ? this.form.registeredProvinceCode
          : "",
        registeredCityCode: this.form.registeredCityCode
          ? this.form.registeredCityCode
          : "",
        registeredCountyCode: this.form.registeredCountyCode
          ? this.form.registeredCountyCode
          : "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchReturnOrderList(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.tblData = data.records;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.loadList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadList();
    },
    // 查看详情
    onDetail(record) {
      let status = "";
      if (
        record.orderStatusValue === "商家审核中" ||
        record.orderStatusValue === "已取消" ||
        record.orderStatusValue === "已退回" ||
        record.orderStatusValue === "待审核" ||
        record.orderStatusValue === "签约中"
      ) {
        status = "approval";
      } else if (record.orderStatusValue === "客户发货中") {
        status = "deliver";
      } else if (
        record.orderStatusValue === "已完成" ||
        record.orderStatusValue === "商家收货中"
      ) {
        status = "receive";
      } else if (record.orderStatusValue === "待退款审核") {
        status = "payment-approval";
      } else if (record.orderStatusValue === "商家退款中") {
        status = "factory-refund";
      }
      this.$router.push({
        name: "OperationReturnedOrderDetail",
        params: { orderSn: record.orderSn, type: "detail", status: status }
      });
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item .ant-form-item-label {
  min-width: 72px !important;
  text-align: left !important;
}

/deep/ .ant-form-item {
  margin-right: 40px;
}
</style>
